<template>
	<div style="width: 100%" class="p-fluid">
		<InfiniteProgress v-show="loading"></InfiniteProgress>
		<div  v-if="userData !== undefined && userHasRole('ROLE_ADMIN')"  class="p-fluid">
			<div v-if="plans !== undefined" class="p-fluid">
				<div v-for="plan in plans" :key="plan.id" class="plan-card ">
					<div class="p-d-flex p-jc-between p-ai-center plan-header card b-card p-shadow-4">
						<div class="left">
							<b>#{{ plan.id }}</b> {{ plan.name }} - <b>{{ plan.priceVatExcl }}€ /mois</b> soit <b>{{  plan.priceVatExcl*12 }}€ /an</b>
						</div>

						<div class="right">
							<div class="p-fluid p-text-center">
								<Button v-if="!plan.showContent"
										icon="pi pi-pencil" label="Editer" @click="plan.showContent = true" class="p-button-outlined p-button-secondary"></Button>
								<Button v-else
										icon="pi pi-times" label="Cacher" @click="plan.showContent = false" class="p-button-outlined p-button-secondary"></Button>
							</div>
						</div>
					</div>
					<div v-show="plan.showContent" class="p-card-content">

						<div class="p-grid p-fluid">
							<div class="p-col-12 p-lg-8">
								<div class="p-field">
									<label for="name1">Nom</label>
									<InputText v-model="plan.name" id="name1" type="text" />
								</div>
							</div>
							<div class="p-col-12 p-lg-4">
								<div class="p-field">
									<label for="visible">Visible</label><br>
									<InputSwitch id="visible" class="p-mt-1" v-model="plan.visible" />
								</div>
							</div>
						</div>
						<div class="p-field">
							<p>Description</p>
							<Textarea v-model="plan.description" :autoResize="true" rows="5" cols="30" />
						</div>
						<h5>Tarification</h5>
						<div class="p-grid p-fluid">
							<div class="p-col-12 p-lg-6">
								<div class="p-field">
									<label for="price_vat_excl">Prix mensuel hors TVA <small class="text-muted">en €</small></label>
									<InputNumber id="price_vat_excl" v-model="plan.priceVatExcl" mode="currency" currency="EUR" locale="fr-FR" />
								</div>
							</div>
							<div class="p-col-12 p-lg-6">
								<div class="p-field text-muted">
									<label for="vat_tx">Taux de TVA <small class="text-muted">soon</small></label>
									<InputNumber id="vat_tx" disabled suffix=" %" :step="0.01" mode="decimal" :min="0" :max="100" locale="fr-FR" />
								</div>
							</div>
						</div>
						<h5>Quota médias </h5>
						<div class="p-grid p-fluid">
							<div class="p-col-12 p-lg-6">
								<div class="p-field">
									<label v-bind:class="{'text-muted': !plan.roles.includes('ROLE_SUPERVISOR')}"
										   for="subUserQuota">Quota utilisateur</label>
									<InputNumber id="subUserQuota" :disabled="!plan.roles.includes('ROLE_SUPERVISOR')" v-model="plan.subUserQuota" :step="1" :min="-1"  />
								</div>
							</div>
							<div class="p-col-12 p-lg-6">
								<div class="p-field">
									<label v-bind:class="{'text-muted': !plan.roles.includes('ROLE_MEDIA')}"
										   for="quota_file">Quota média(s)</label>
									<InputNumber id="quota_file" :disabled="!plan.roles.includes('ROLE_MEDIA')" v-model="plan.fileQuota" :step="1" :min="-1"  />
								</div>
							</div>
						</div>
						<h5>Fonctionnalités</h5>
						<div class="p-fluid">
							<RoleEditor @input="plan.roles = $event" :value="plan.roles" ></RoleEditor>
						</div>

						<div class="p-grid p-fluid p-mt-4">
							<Button icon="pi pi-save" label="Mettre à jour !"
									@click="updatePlan(plan)" class="p-button-outlined p-button-info"></Button>
						</div>
					</div>

				</div>


				<div class="p-fluid text-muted">
					<Button icon="pi pi-plus" label="Ajouter un nouveau plan"
							@click="addPlan" class="p-button-secondary"></Button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import userMixin from "../../../mixins/userMixin";
	import PlanService from "../../../service/PlanService";
	import RoleEditor from './RoleEditor.vue';
	import InfiniteProgress from '../../InfiniteProgress.vue';

export default {
	data() {
		return {
			loading: true,
			plans: undefined,
		}
	},
	created(){
		this.planService = new PlanService();
	},
	mounted(){
		this.loadData();
	},
	methods: {
		addPlan(){
			this.plans.push({
				id: undefined,
				name: '',
				description: '',
				roles: [],
				subUserQuota: 0,
				fileQuota: 0,
				visible: false,
				showContent: true,

			})
		},
		updatePlan(plan){
			this.loading = true;
			if(plan.id === undefined){
				this.planService.createPlan(plan)
						.then(res => {
							if(res.data.error === true || (res.status !== 200 && res.status !== 201)){
								throw new Error(res.data.message);
							}
						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							this.loading = false;
							this.loadData();
						});
				return;
			}


			this.planService.updatePlan('/api/plans/' + plan.id, plan)
					.then(res => {
						if(res.data.error === true || res.status !== 200){
							throw new Error(res.data.message);
						}
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
						this.loadData();
					})
		},
		loadData(){
			this.planService.listPlans()
					.then(res => {
						if(res.data.error === true || res.status !== 200){
							throw new Error(res.data.message);
						}

						this.plans = res.data['hydra:member'];

						console.log(res.data);
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
					})
		}
	},
	mixins:[userMixin],
	computed: {
		...mapState({
			userData: 'user',
		}),
	},
	components: {
		RoleEditor,
		InfiniteProgress,
	}
}
</script>

<style scoped>

</style>
